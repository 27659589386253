import './vendors/h5bp';

/* 
 * Mapa mediante la API de Google Maps
 */

function googleMaps() {
  
  // Ubicación LAT
  var lugar = new google.maps.LatLng(-31.842475, -60.517566);
  var centro = new google.maps.LatLng(-31.842475, -60.517566);

  // Estilos
  var estilos = [ 
  { "featureType": "road", "elementType": "geometry", "stylers": [ { "color": "#ececec" } ] },
  { "featureType": "landscape", "stylers": [ { "color": "#ffffff" } ] },
  { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#6b7d5d" } ] },
  { "featureType": "water", "stylers": [ { "color": "#6b7d5d" } ] } ];

  // Opciones del mapa
  var mapOptions = {
    center: centro,
    zoom: 11,
    styles: estilos,
    scrollwheel: false
  };

  // Crear mapa
  var map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);

  // Marcador
  var marker = new google.maps.Marker({
    position: lugar,
    icon: "../assets/media/location-pin.png",
    map: map,//lo asocio al mapa
    optimized: false,
    title: 'Escuela Normal Rural "Juan B. Alberdi"'
  });

  var infoWindow = new google.maps.InfoWindow({
      content: "<p style='text-align:center'>Ruta Provincial Nº 11, Km.10 ½ <br>Oro Verde - Entre Ríos</p>"
  });

  google.maps.event.addListener(marker, 'click', function () {
      infoWindow.open(map, marker);
  });

}


/* 
 * Envío del formulario
 *
 * - Ejecucion en: $(document).ready()
 */

function formsGo(){

  $("#contact-submit").on('click', function(e){//formulario de contacto
    e.preventDefault();

    var okflag=true;
    $('input,textarea').filter('[required]:visible').each(function(i, requiredField){
      if($(requiredField).val()==''){
        if(okflag==true)$('#msg-all').fadeIn().delay(5000).fadeOut();
        okflag=false; 
        $(requiredField).addClass("contact-error");
      }
      else $(requiredField).removeClass("contact-error");
    });

    if(okflag==true)
      $('#msg').addClass("backGload").fadeIn().load('contacto.php', {
        nombre: $('#contact-name').val(),
        email: $('#contact-email').val(),
        telefono: $('#contact-phone').val(),
        mensaje: $('#contact-message').val()
      }, function(){
        $('input, textarea').val("");
        $('#msg').removeClass("backGload");
      });

  });

}


/* 
 * Ejectuo las funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
function goMaterialize() {
  $(".button-collapse").sideNav();
  $('.materialboxed').materialbox();
  $('.slider').slider({full_width: true});
  $('select').material_select();
  $('.dropdown-button').dropdown({
      inDuration: 300,
      outDuration: 225,
      constrain_width: false, // Does not change width of dropdown to that of the activator
      hover: true, // Activate on hover
      gutter: 0, // Spacing from edge
      belowOrigin: true, // Displays dropdown below the button
      alignment: 'left' // Displays dropdown with edge aligned to the left of button
    }
  );
}

/* 
 * Ejectuo las llamadas a slick
 *
 * - Ejecucion en: $(document).ready()
 */
function goSlick () {

  $('.last-docs-slider').slick({
    autoplay: true,
    dots: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  });

}


/* 
 * Ejectuo las llamadas a Fancybox
 * - Ejecucion en: $(document).ready()
 */
function goFancy () {

  $(".fancybox").fancybox({
      openEffect  : 'fade',
      closeEffect : 'fade',
      padding: 0
  });

}


/* 
 * Ejectuo las llamadas a Fancybox
 * - Ejecucion en: $(document).ready()
 */
function sameHeight() {
  "use strict";

  var maxHeight = 0;
  $('.same-height').each(function() {
    if ( $(this).height() > maxHeight ) {
      maxHeight = $(this).height();
    }
  });
  $('.same-height').css("height", maxHeight);    
}


/* 
* Instanciamos las funciones :D
*
*/
$(document).ready (function () {
  "use strict";

  goFancy ();
  goMaterialize();
  goSlick();
  formsGo();

  if($('#map-canvas').length) googleMaps();
  if($('.same-height').length) sameHeight();

});